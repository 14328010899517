import { differenceInYears, format } from 'date-fns';

export function formatDate(date: string, withHours?: boolean) {
  const nuxtApp = useNuxtApp();
  const { locale } = nuxtApp.$i18n;
  return format(
    new Date(date),
    `${locale.value === 'en' ? 'MM/dd/yyyy' : 'dd/MM/yyyy'}${withHours ? ' HH:mm' : ''}`,
  );
}

export function getAgeFromDate(date: string) {
  return differenceInYears(new Date(), new Date(date));
}
